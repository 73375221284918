<template>
    <div class="wrapper">
        <admin-title :title="$route.meta.name"></admin-title>

        <el-steps style="width: 50%; margin: 0 auto" :active="active" finish-status="success">
            <el-step title="基本信息"></el-step>
            <el-step title="详情"></el-step>
        </el-steps>
        <el-divider/>


        <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
            <el-row :gutter="20">
                <div v-show="active === 0">
                    <el-col :span="12">
                        <el-form-item prop="title" label="标题">
                            <el-input size="small" v-model="formData.title" placeholder="标题"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item prop="type" label="付费类型" label-width="80px">
                            <el-select v-model="formData.type" placeholder="请选择">
                                <el-option key="1" label="免费" value="1"></el-option>
                                <el-option key="2" label="付费" value="2"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="封面图片" prop="phone">
                            <file-upload-img :url.sync="formData.cover_image"></file-upload-img>
                            <span>(290 * 240)</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="下载文件" prop="phone">
                            <file-upload-video :videoUrl.sync="formData.files"></file-upload-video>
                        </el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-button style="margin-top: 50px; margin-left: 50px;" @click="goNext" type="primary">下一步</el-button>
                    </el-col>
                </div>

                <div v-show="active === 1">
                    <el-form-item class="art-editor" label="内容" prop="content" label-width="100px">
                        <Editor style="width: 250%; min-height: 600px;" :showBtn="true" :content.sync="formData.content"></Editor>
                    </el-form-item>

                    <el-form-item label=" ">
                        <el-button size="small" type="primary" @click="goPev()">上一步</el-button>
                        <el-button size="small" type="primary" @click="save">保存</el-button>
                        <el-button size="small" type="" @click="cancel">取消</el-button>
                    </el-form-item>
                </div>
            </el-row>






        </el-form>
    </div>
</template>

<script>
import {detail, addDownload, editDownload} from './api'
import {DICT_SYSTEM_SEX, DICT_SYSTEM_STATUS} from '@/enum/dict'
import FileUploadVideo from "@/components/uploader/uploader-video.vue";
import FileUploadImg from "@/components/uploader/uploader-img.vue";
import Editor from '@/components/editor/index.vue'

export default {
    name: 'AddOrEdit',
    components: {FileUploadImg, FileUploadVideo, Editor},

    data() {
        return {
            active: 0,
            options: [],
            DICT_SYSTEM_SEX,
            DICT_SYSTEM_STATUS,
            formData: {
                title: '',
                content: '',
                cover_image: '',
                files: {}
            },

            rules: {
                name: [{required: true, message: '标题不能为空', trigger: 'blur'}],
            }
        }
    },

    mounted() {
        if (this.$route.params.id) this.getDetail()
    },

    methods: {
        goNext() {
            if (this.active++ > 1) this.active = 0;
        },
        goPev() {
            this.active = 0
        },
        getUploadUrl({fileUrl}) {
            this.formData.cover_image = fileUrl
        },
        getVideoUrl({fileUrl}) {
            this.formData.files = fileUrl
        },
        updateStatus(val) {
            this.formData.status = val
        },
        save() {
            this.$refs.form.validate(async valid => {
                if (valid) {
                    if (this.formData.id) {
                        // 编辑
                        await editDownload(this.formData).then(() => {
                            this.$message.success('编辑成功')
                            this.$router.back()
                        })
                    } else {
                        // 新增
                        await addDownload(this.formData).then(() => {
                            this.$message.success('添加成功')
                            this.$router.back()
                        })
                    }
                } else {
                    return false
                }
            })
        },
        async getDetail() {
            this.formData = await detail(this.$route.params.id)
            this.formData.status = this.formData.status.toString()
            this.formData.type = this.formData.type.toString()
        },
        cancel() {
            this.$router.back(-1)
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .el-form /deep/ {
    .el-form-item {
      .el-form-item__content {
        width: 500px;

        .el-cascader,
        .el-select {
          width: 100%;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.el-form /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
